import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\app\\globals.css");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\app\\media.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\components\\Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\components\\Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\components\\Tawktomessenger.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\node_modules\\@next\\third-parties\\dist\\google\\ga.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\node_modules\\@next\\third-parties\\dist\\google\\gtm.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\node_modules\\@next\\third-parties\\dist\\ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\node_modules\\bootstrap\\dist\\css\\bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Muhammad Touqeer\\Desktop\\git\\assignmentwritingserviceae\\node_modules\\next\\font\\google\\target.css?{\"path\":\"app\\\\layout.js\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
